import React from "react"
import { graphql } from "gatsby"

import SEO from "components/seo"
import Layout from "components/layout"
import { Team, Presentation } from "components/paragraphs"
import { Wysiwyg } from "components/helpers"
import { getSrc } from "gatsby-plugin-image"

import "scss/agency.scss"

const Agency = ({ data }) => {
  const {
    nodeAgence: {
      title,
      field_description,
      relationships: { field_presentations, field_team },
    },
  } = data

  const metadata = {
    url: "/agence",
    title: title,
    description: field_description?.processed,
    image: getSrc(
      field_presentations[0].relationships?.field_image_media?.relationships
        ?.field_media_image?.localFile
    ),
  }

  return (
    <Layout>
      <SEO {...metadata} />
      <div className="header-blue">
        <div className="agency__wrapper">
          <div className="container">
            <h1
              className="agency__title"
              data-sal-duration="1500"
              data-sal="zoom-in"
            >
              {title}
            </h1>
            <Wysiwyg
              className="agency__description"
              data-sal-duration="1500"
              data-sal="zoom-in"
              {...field_description}
            />
          </div>
          {field_presentations.map((props) => (
            <Presentation {...props} key={props.id} />
          ))}
          <Team {...field_team} />
        </div>
      </div>
    </Layout>
  )
}

export default Agency

export const query = graphql`
  query AgencyQuery($locale: String = "fr") {
    nodeAgence(langcode: { eq: $locale }, path: { alias: { eq: "/agency" } }) {
      field_description {
        processed
      }
      title
      relationships {
        field_presentations {
          ...PresentationParagraph
        }
        field_team {
          ...TeamParagraph
        }
      }
    }
  }
`
